#External Libraries
import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
# import VueAutonumeric from 'vue-autonumeric'
import AxiosRequest from "../utils/axios-request"
import UniqBy from 'lodash.uniqby'


# Local imports
import fetchCatalogues from '../methods/catalogues/fetch-catalogues'
import toCurrency from '../filters/to_currency'

Vue.use(TurbolinksAdapter)
document.addEventListener 'turbolinks:load', () ->
  el = document.getElementById 'index-catalogues'
  return false unless el?
  data = ->
    {searchParams} = new URL(window.location.href) # Url params
    categories = []
    subcategories = []
    page = 1
    sort =
      updated_at: 'desc'
      title: false

    # Url params
    categories_param = parseInt(searchParams.get('category'))
    subcategories_param = parseInt(searchParams.get('subcategory'))

    # Negotiation
    categories.push(categories_param) if categories_param
    subcategories.push(subcategories_param) if subcategories_param
    page |= parseInt searchParams.get('page')
    keywords = searchParams.get('keyword')
    {
      catalogues: []
      filter_interactions: 1
      subcategories: []
      subcategory_names: []
      page
      loading: true
      search_filters: {
        categories
        subcategories
        keywords
      }
      sort
    }
  new Vue {
    el
    data
    mounted: ->
      theCategories = @search_filters.categories
      @getSubcategories(theCategories, false)
    methods: {
      fetchCatalogues
      filtersChanged: ->
        window.scrollTo(0, 0)
        @page = 1
        @fetchCatalogues()
      assignProperty: (property, value) ->
        @search_filters[property] = value
      clearProperty: (property, reload = true) ->
        @search_filters[property] = '';
        @fetchCatalogues() if reload
      infiniteHandler: ($state) ->
        @fetchCatalogues('infinite', $state)
      resetState: ->
        history.pushState({}, 'Ver Todo nuestro catálogo', document.location.origin + document.location.pathname)
        Object.assign @$data, data()
      getSubcategories: (categories, launch_control = true) ->
        @search_filters.subcategories = [] if launch_control
        subcats = []
        if categories.length
          for category in categories
            AxiosRequest("/categories/#{category}.json").then ({ data }) =>
              subcats.push(data...)
              @subcategories = UniqBy(subcats, 'id')
        else
          @subcategories = []

      sortControl: (order) ->
        @sort = order
        @filtersChanged()
    }
    watch: {
      'search_filters.categories':
        handler: (categories) ->
          @filtersChanged()
          @getSubcategories(categories)
        immediate: true
      'search_filters.subcategories': -> @filtersChanged()
      'search_filters.keywords': -> @filtersChanged()

    }
    components: {
      InfiniteLoading
    }
    computed: {
      active_filters: ->
        f = @search_filters
        f.categories.length || f.keywords
    }
    filters: {
      toCurrency
    }
  }
