import {get} from 'axios'
import {debounce} from 'throttle-debounce'
import UniqBy from 'lodash.uniqby'

export default debounce(1000, false, (mode = 'get', $state) ->
  @loading = true
  {
    categories
    subcategories
    keywords

  } = @search_filters
  q = {
    subcategory_id_in: subcategories if subcategories
    category_id_in: categories if categories.length
    serie_i_cont_all: keywords if keywords

  }
  request = get('/catalogues/search.json', {params: {id: @id, q, @page, sort: [@sort]}})
  if mode == 'infinite'
    request.then ({data}) =>
      @loading = false
      quantity = data.catalogues.length
      new_catalogues = @catalogues
      new_catalogues.push(data.catalogues...)
      @catalogues = UniqBy(new_catalogues, 'id')
      if (quantity < 4)
        $state.complete()
        @page = 0
      else
        @page++
        $state.loaded()
  if mode == 'get'
    @catalogues = []
    request.then ({data}) =>
      @page++
      @catalogues = data.catalogues
      @loading = false
)
